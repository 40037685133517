
import UsuarioService from "../../../Services/UsuarioService";
import MenuTelefonoLada from "../../leads/components/MenuTelefonoLada.vue";
import SeccionIndicadoresContraseniaValida from "./components/SeccionIndicadoresContraseniaValida.vue";
import ModalSubirFotoGlobal from "../../../components/modales/ModalSubirFotoGlobal.vue";
import FotoThumbnailContenedorGlobal from "../../../components/globales/FotoThumbnailContenedorGlobal.vue";
export default {
  name: "usuario-gestor",
  components: {
    MenuTelefonoLada,
    SeccionIndicadoresContraseniaValida,
    ModalSubirFotoGlobal,
    FotoThumbnailContenedorGlobal,
  },
  data() {
    return {
      breadcrumbs: [
        { etiqueta: "Configuración" },
        { etiqueta: "Usuarios", to: { name: "usuariosGestor" } },
        { etiqueta: "Detalle" },
      ],
      menuStatusOpciones: [
        {
          status: 200,
          statusNombre: "Activo",
        },
        {
          status: 301,
          statusNombre: "Inactivo",
        },
      ],
      usuarioObj: {},
      opcionesToolbar: [],
      ladas: this.constantesLadas.Ladas,
      usuarioId: this.$route.params.id,
      perfiles: [],
      columnasPerfiles: [
        {
          label: "Nombre",
          name: "titulo",
          field: "titulo",
          align: "left",
          headerStyle: "width: 20%",
        },
        {
          label: "Descripción",
          name: "descripcion",
          field: "descripcion",
          align: "left",
          headerStyle: "width: 60%",
        },
        {
          label: "Registro",
          name: "registroFecha",
          field: "registro_fecha",
          align: "left",
          headerStyle: "width: 20%",
          format: (val) => this.formatearFecha(val, 2, true),
        },
      ],
      modalEditar: false,
      editarUsuarioObj: {
        nombre: "",
        apellido: "",
        telefono_lada: "+52",
        ladaIcono: "https://flagcdn.com/mx.svg",
        telefono_sin_lada: "",
        email: "",
      },
      modalEditarPerfiles: false,
      perfilesOpciones: [],
      perfilesEditados: [],
      modalEditarContrasenia: false,
      mostrarContrasenia: false,
      editarContraseniaObj: {
        contrasenia: "",
        contraseniaRepetida: "",
      },
      modalEliminar: false,
      modalActualizarFoto: false,
      urlFotoPerfilThumb: "",
    };
  },
  mounted() {
    this.cargaInicial();
  },
  computed: {
    crearNombreCorto() {
      let nombreCorto = this.editarUsuarioObj.nombre.split(" ")[0];
      let apellidoCorto = this.editarUsuarioObj.apellido.split(" ")[0];
      return nombreCorto + " " + apellidoCorto;
    },
  },
  methods: {
    async cargaInicial() {
      this.loader(true);
      await this.obtenerDetalle(false);
      this.loader(false);
    },
    async obtenerDetalle(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        usuarioId: this.usuarioId,
      };
      await UsuarioService.obtenerDetalle(datos)
        .then((resp) => {
          this.usuarioObj = resp.info;
          this.perfiles = resp.perfiles;
          this.urlFotoPerfilThumb = resp.urlFotoPerfilThumbnail;

          this.llenarOpcionesToolbar();
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async obtenerConcentradoOpciones(loader = true) {
      if (loader) this.loader(true);
      await UsuarioService.obtenerConcentradoOpciones()
        .then((resp) => {
          this.perfilesOpciones = resp.perfiles;
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async editarUsuario(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        ...this.editarUsuarioObj,
        lada: this.editarUsuarioObj.telefono_lada,
        telefono: this.editarUsuarioObj.telefono_sin_lada,
        usuarioId: this.usuarioId,
        nombreCorto: this.crearNombreCorto,
      };
      await UsuarioService.editar(datos)
        .then(async (resp) => {
          this.cerrarModalEditar();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async editarPerfiles(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        usuarioId: this.usuarioId,
        perfiles: JSON.stringify(this.perfilesEditados),
      };
      await UsuarioService.editarPerfiles(datos)
        .then(async (resp) => {
          this.cerrarModalEditarPerfiles();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async editarContrasenia(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        usuarioId: this.usuarioId,
        contrasenia: this.editarContraseniaObj.contrasenia,
      };
      await UsuarioService.editarContrasenia(datos)
        .then(async (resp) => {
          this.cerrarModalEditarContrasenia();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async eliminarUsuario(loader = true) {
      if (loader) this.loader(true);
      let datos = {
        usuarioId: this.usuarioId,
      };
      await UsuarioService.eliminar(datos)
        .then(async (resp) => {
          this.cerrarModalEliminar();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          if (loader) this.loader(false);
        });
    },
    async actualizarStatus(statusNuevo) {
      if (this.usuarioObj.status == statusNuevo) return;

      this.loader(true);
      let datos = {
        usuarioId: this.usuarioId,
        statusNuevo: statusNuevo,
      };
      await UsuarioService.actualizarStatus(datos)
        .then(async (resp) => {
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    async actualizarFoto(archivo) {
      this.loader(true);
      let datos = {
        usuarioId: this.usuarioId,
        archivo: archivo.imagen,
      };
      await UsuarioService.actualizarFoto(datos)
        .then(async (resp) => {
          this.cerrarModalActualizarFoto();
          await this.obtenerDetalle(false);
          this.mostrarAlerta(resp.mensaje, "exito");
        })
        .catch((error) => this.mostrarAlerta(error, "error"))
        .then(() => {
          this.loader(false);
        });
    },
    llenarOpcionesToolbar() {
      this.opcionesToolbar = [
        {
          texto: "Editar datos generales",
          icono: "editar",
          accion: "abrirModalEditar",
          disabled: this.usuarioObj.status == 300,
        },
        {
          texto: "Editar foto de perfil",
          icono: "editar",
          accion: "abrirModalActualizarFoto",
          disabled: this.usuarioObj.status == 300,
        },
        {
          texto: "Editar contraseña",
          icono: "editar",
          accion: "abrirModalEditarContrasenia",
          disabled: this.usuarioObj.status == 300,
        },
        {
          texto: "Dar de baja",
          icono: "eliminar",
          accion: "abrirModalEliminar",
          disabled: this.usuarioObj.status == 300,
        },
      ];
    },
    async abrirModalEditar() {
      this.editarUsuarioObj = _.cloneDeep(this.usuarioObj);
      if (this.editarUsuarioObj.telefono_lada) {
        this.editarUsuarioObj.ladaIcono = this.ladas.find((l) =>
          l.callingCodes.includes(
            this.editarUsuarioObj.telefono_lada.substring(1)
          )
        ).flag;
      } else {
        this.editarUsuarioObj.ladaIcono = "https://flagcdn.com/mx.svg";
        this.editarUsuarioObj.telefono_lada = "+52";
      }
      this.modalEditar = true;
    },
    cerrarModalEditar() {
      this.editarUsuarioObj = {
        nombre: "",
        apellido: "",
        telefono_lada: "",
        telefono_sin_lada: "",
        email: "",
      };
      this.modalEditar = false;
    },
    async abrirModalEditarPerfiles() {
      if (_.isEmpty(this.perfilesOpciones))
        await this.obtenerConcentradoOpciones();
      let perfiles = _.cloneDeep(this.perfiles);
      this.perfilesEditados = perfiles.map((p) => p.perfil_id);
      this.modalEditarPerfiles = true;
    },
    cerrarModalEditarPerfiles() {
      this.modalEditarPerfiles = false;
      this.perfilesEditados = [];
    },
    abrirModalEditarContrasenia() {
      this.modalEditarContrasenia = true;
    },
    cerrarModalEditarContrasenia() {
      this.modalEditarContrasenia = false;
      this.editarContraseniaObj = {
        contrasenia: "",
        contraseniaRepetida: "",
      };
    },
    abrirModalEliminar() {
      this.modalEliminar = true;
    },
    cerrarModalEliminar() {
      this.modalEliminar = false;
    },
    seleccionarLada(lada) {
      this.editarUsuarioObj.ladaIcono = lada.flag;
      this.editarUsuarioObj.telefono_lada = "+" + lada.callingCodes[0];
    },
    abrirModalActualizarFoto() {
      this.modalActualizarFoto = true;
    },
    cerrarModalActualizarFoto() {
      this.modalActualizarFoto = false;
    },
  },
};
