
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";
import InputArchivo from "../inputs/InputArchivo.vue";
import constantesArchivo from "@/Constantes/Archivo.js";

export default {
  name: "modal-subir-foto-global",
  components: {
    Cropper,
    InputArchivo,
  },
  props: {
    model: { type: Boolean, required: true },
    tituloModal: {
      type: String,
      default: "Subir foto",
    },
    tiposArchivosAceptados: {
      type: String,
      default: constantesArchivo.TIPOS_ARCHIVOS_ACEPTADOS_IMAGEN,
    },
  },
  data() {
    return {
      mostrar: false,
      archivo: {
        name: "",
      },
      imagen: {
        src: null,
        type: null,
      },
    };
  },
  watch: {
    model(val) {
      this.mostrar = val;
    },
  },
  methods: {
    onClose() {
      // Se revoca la URL del objeto blob para permitir que el recolector de basura destruya el archivo cargado
      if (this.imagen.src) {
        URL.revokeObjectURL(this.imagen.src);
      }
      this.archivo = {
        name: "",
      };
      this.imagen = {
        src: null,
        type: null,
      };
      this.$emit("cerrar-modal");
    },
    subirArchivo() {
      let { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob((blob) => {
        let imagen = new File([blob], this.archivo.name, {
          type: blob.type,
        });
        this.archivo.imagen = imagen;
        this.$emit("subir-foto", this.archivo);
      }, this.archivo.type);
    },
    guardarArchivo(archivo) {
      this.archivo = archivo;
      this.cargarImagenCroper();
    },
    cargarImagenCroper() {
      // Se crea el vínculo del blob al archivo para optimizar el rendimiento
      let blob = URL.createObjectURL(this.archivo);

      // Se crea un nuevo FileReader para leer los datos binarios de la imagen
      let lector = new FileReader();
      // Se define callback para ejecutar cuando FileReader termine de ejecutar
      lector.onload = (e) => {
        // Nota: Se utiliza arrowfunction de modo que "this.image" refiere a la imagen del componente Vue
        this.imagen = {
          // Se establece fuente de la imagen (se verá como blob: http://example.com/2c5270a5-18b5-406e-a4fb-07427f5e7b94)
          src: blob,
          // Se determina el tipo de imagen para conservarla durante la extracción de la imagen
          type: this.obtenerMimeType(e.target.result, this.archivo.type),
        };
      };
      // Se inicia el trabajo del lector: lee el archivo como una URL de datos (formato base64)
      lector.readAsArrayBuffer(this.archivo);
    },
    obtenerMimeType(file, fallback = null) {
      let byteArray = new Uint8Array(file).subarray(0, 4);
      let header = "";
      for (let i = 0; i < byteArray.length; i++) {
        header += byteArray[i].toString(16);
      }
      switch (header) {
        case "89504e47":
          return "image/png";
        case "47494638":
          return "image/gif";
        case "ffd8ffe0":
        case "ffd8ffe1":
        case "ffd8ffe2":
        case "ffd8ffe3":
        case "ffd8ffe8":
          return "image/jpeg";
        default:
          return fallback;
      }
    },
  },
};
