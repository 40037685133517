
export default {
  name: "foto-thumbnail-contenedor-global",
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  data() {
    return { mostrarImagen: false };
  },
  watch: {
    url() {
      this.mostrarImagen = false;
    },
  },
};
