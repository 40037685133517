import { render, staticRenderFns } from "./UsuarioDetalle.vue?vue&type=template&id=4f1a6b72&"
import script from "./UsuarioDetalle.vue?vue&type=script&lang=js&"
export * from "./UsuarioDetalle.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import {QPage,QScrollArea,QTable,QForm,QInput,QBtn,QSelect,QItem,QItemSection,QCheckbox,QIcon} from 'quasar'
component.options.components = Object.assign(Object.create(component.options.components || null), component.options.components || {}, {QPage,QScrollArea,QTable,QForm,QInput,QBtn,QSelect,QItem,QItemSection,QCheckbox,QIcon})
